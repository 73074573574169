import React from "react";
import { toast } from "react-toastify";

const LogoutModal = ({ setIsDialogOpen, setIsUserLogin }) => {
  const handleLogout = () => {
    localStorage.removeItem("tvToken");
    localStorage.removeItem("tvServer");
    localStorage.removeItem("tvAccount");
    setIsUserLogin(false);
    setIsDialogOpen(false);
    toast("Logout successful");
    var iframe = document.getElementsByTagName("iframe")[0];
    var button = iframe.contentDocument.querySelector(
      'button[data-name="order-panel-button"]'
    );
    if (button) {
      button.style.display = "none";
    }
    window.broker.logoutAccount();
  };
  return (
    <dialog open>
      <div className="modal">
        <div className="modal-header">
          <div>
            <img src="/logo.svg" height={22} />
          </div>
          <div onClick={() => setIsDialogOpen(false)}>
            <img style={{ cursor: "pointer" }} src="/cross-icon.svg" />
          </div>
        </div>
        <div>
          <h3>Log Out Confirmation</h3>
          <h6>Do you really want to log out?</h6>

          <div>
            <button onClick={handleLogout} className="btn">
              Yes
            </button>
            <button
              onClick={() => setIsDialogOpen(false)}
              className="btn-outline"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default LogoutModal;

import React from "react";

const SessionModal = ({ setIsDialogOpen }) => {
  return (
    <dialog open>
      <div className="modal">
        <div className="modal-header">
          <div>
            <img src="/logo.svg" height={22} />
          </div>
          <div onClick={() => setIsDialogOpen(false)}>
            <img style={{cursor:"pointer"}} src="/cross-icon.svg" />
          </div>
        </div>
        <div>
          <h3>Session Disconnected</h3>
          <h6>
          Looks like you've been inactive for a while. Would you like to resume trading?
          </h6>

          <div>
            <button  onClick={() => window.location.reload()} className="btn">
              Yes
            </button>
            <button onClick={()=>setIsDialogOpen(false)} className="btn-outline">
              No
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default SessionModal;

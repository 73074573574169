// eventDispatcher.js

const eventListeners = new Map();

export function addEventListener(eventType, callback) {
  if (!eventListeners.has(eventType)) {
    eventListeners.set(eventType, new Set());
  }
  eventListeners.get(eventType).add(callback);
}

export function removeEventListener(eventType, callback) {
  if (eventListeners.has(eventType)) {
    eventListeners.get(eventType).delete(callback);
  }
}

export function dispatchEvent(eventType, data) {
  if (eventListeners.has(eventType)) {
    for (const listener of eventListeners.get(eventType)) {
      listener(data);
    }
  }
}

import React, { useEffect, useState } from "react";
import { GN_API_URL, FN_API_URL } from "../trading-view/const";
import { toast } from "react-toastify";
import Fingerprint2 from "fingerprintjs2";
import platform from "platform";
import { LoadingButton } from "./LoadingButton";

const LoginModal = ({ setIsDialogOpen, isDialogOpen, setIsUserLogin }) => {
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [cid, setCid] = useState("");
  const [loading, setLoading] = useState(false);
  const [server, setServer] = useState("fn");

  useEffect(() => {
    Fingerprint2.get(function (components) {
      var fingerprint = Fingerprint2.x64hash128(
        components
          .map(function (pair) {
            return pair.value;
          })
          .join(),
        31
      );
      setCid(fingerprint);
    });
  }, []);

  // ping time
  var performanceTiming = window.performance.timing;
  var pingTime =
    performanceTiming.responseEnd - performanceTiming.navigationStart;

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setLoading(true);

    if (loading) return;
    let apiUrl;
    if (server == "gn") {
      apiUrl = GN_API_URL;
    } else {
      apiUrl = FN_API_URL;
    }

    try {
      const res = await fetch(`${apiUrl}/authorize`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login: parseInt(account, 10),
          password: password,
          cid: cid,
          ping: pingTime,
          browserDetails: platform.description,
        }),
      });

      const responseData = await res.json();

      if (res.ok && responseData.d && responseData.d.token) {
        localStorage.setItem("tvToken", responseData.d.token);
        localStorage.setItem("tvAccount", account);
        localStorage.setItem("tvServer", server);

        setLoading(false);
        setIsUserLogin(true);
        setIsDialogOpen(false);
        toast(responseData.message);
        window.broker.loginAccount(account, responseData.d.token,server);
        var iframe = document.getElementsByTagName("iframe")[0];
        var button = iframe.contentDocument.querySelector(
          'button[data-name="order-panel-button"]'
        );
        if (button) {
          button.style.display = "block";
        }
      } else {
        setLoading(false);
        toast(responseData.message);
        throw new Error("Login failed or token not received");
      }
    } catch (error) {
      console.error("Error during login:", error.message);
    }
  };

  return (
    <dialog open={isDialogOpen}>
      <div className="modal">
        <div className="modal-header">
          <div>
            <img src="/logo.svg" height={22} alt="Logo" />
          </div>
          <div onClick={() => setIsDialogOpen(false)}>
            <img
              style={{ cursor: "pointer", marginRight: "-20px" }}
              src="/cross-icon.svg"
              alt="Close"
            />
          </div>
        </div>
        <form onSubmit={handleLogin}>
          <div className="form-item">
            <label htmlFor="account">Account</label>
            <input
              type="number"
              id="account"
              placeholder="123232"
              required
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />
          </div>
          <div className="form-item">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="*****"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          
          <div className="form-item">
            <label htmlFor="server">Server</label>
            <select disabled onChange={(e) => setServer(e.target.value)}>
              <option value="fn">GrowthNext-Demo-02</option>
            </select>
          </div>
         
          <button type="submit" className="btn">
            {loading ? <LoadingButton /> : <span>Submit</span>}
          </button>
        </form>
      </div>
    </dialog>
  );
};

export default LoginModal;

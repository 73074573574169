import React, { useEffect, useRef, useState } from "react";
import TradingView from "../charting_library/charting_library.standalone";
import { dataFeeder } from "../trading-view/datafeed";
import { Broker } from "../trading-view/broker";
import LoginModal from "./LoginModal";
import SessionModal from "./SessionModal";
import LogoutModal from "./LogoutModal";
import { defaultChart } from "../data/charts";
import TvLogo from "./TvLogo";

export const TVChartContainer = () => {
  const [isBrokerReady, setIsBrokerReady] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isUserLogin, setIsUserLogin] = useState(false);

  function getTradingViewTheme(name) {
    let val = window.localStorage.getItem(name);
    if (!val && name === "theme") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      )
        return "dark";
      else return "light";
    }
    return val;
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/jsx";
    script.src = "%PUBLIC_URL%/charting_library/charting_library.js";
    document.head.appendChild(script);

    let widget = (window.tvWidget = new TradingView.widget({
      auto_save_delay: 5,
      symbol: "EURUSD", // default symbol
      interval: "1", // default interval
      fullscreen: true, // displays the chart in the fullscreen mode
      container: "tv_chart_container",
      datafeed: dataFeeder,
      library_path: "/charting_library/",
      locale: "en",
      disabled_features: ["show_interval_dialog_on_key_press"],
      enabled_features: [
        "show_symbol_logos",
        "display_market_status",
        "popup_hints",
        "seconds_resolution",
        "tick_resolution",
        "trading_notifications",
        "show_trading_notifications_history",
      ],
      studies_access: {
        type: "black",
        tools: [
          {
            name: "Accumulation/Distribution",
          },
          {
            name: "Ease Of Movement",
          },
          {
            name: "Aroon",
          },
          {
            name: "Money Flow Index",
          },
          {
            name: "52 Week High/Low",
          },
          {
            name: "Trend Strength Index",
          },
          {
            name: "Chaikin Money Flow",
          },
          {
            name: "Chaikin Oscillator",
          },
          {
            name: "Coppock Curve",
          },
          {
            name: "Detrended Price Oscillator",
          },
          {
            name: "Ease of Movement",
          },
          {
            name: "Envelopes",
          },
          {
            name: "Historical Volatility",
          },
          {
            name: "Hull Moving Average",
          },
          {
            name: "Klinger Oscillator",
          },
          {
            name: "McGinley Dynamic",
          },
          {
            name: "Net Volume",
          },
          {
            name: "On Balance Volume",
          },
          {
            name: "Parabolic SAR",
          },
          {
            name: "Pivot Points Standard",
          },
          {
            name: "Price Oscillator",
          },
          {
            name: "Price Volume Trend",
          },
          {
            name: "Rate Of Change",
          },
          {
            name: "Ratio",
          },
          {
            name: "TRIX",
          },
          {
            name: "Triple EMA",
          },
          {
            name: "True Strength Indicator",
          },
          {
            name: "Typical Price",
          },
          {
            name: "Ultimate Oscillator",
          },
          {
            name: "Volatility Close-to-Close",
          },
          {
            name: "VWAP",
          },
          {
            name: "VWMA",
          },
          {
            name: "Volume Oscillator",
          },
          {
            name: "Volume Profile Fixed Range",
          },
          {
            name: "Volume Profile Visible Range",
          },
          {
            name: "Volume",
          },
          {
            name: "ZigZag",
          },
        ],
      },
      charts_storage_api_version: "1.1",
      client_id: "trading_platform_demo",
      user_id: "public_user",
      theme: getTradingViewTheme("theme"),
      time_frames: [
        // { text: "6m", resolution: "1m", description: "6 Month" },
        // { text: "3m", resolution: "1w", description: "3 Month" },
        { text: "1m", resolution: "1D", description: "1 Month" },
      ],
      overrides: {
        "tradingProperties.extendLeft": false,
        "tradingProperties.horizontalAlignment": 1,
      },
      widgetbar: {
        details: true,
        news: false,
        watchlist: true,
        datawindow: true,
        watchlist_settings: {
          default_symbols: [
            "###Forex",
            "EURUSD",
            "GBPUSD",
            "USDJPY",
            "USDCAD",
            "GBPJPY",
            "###Indices",
            "SPX500",
            "US30",
            //"NDX100",
            "###Commodity",
            "XAUUSD",
            "XAGUSD",
            //"###Crypto",
            //"BTCUSD",
            //"ETHUSD",
          ],
          readonly: false,
        },
      },
      rss_news_feed: {
        default: [
          {
            url: "https://demo-feed-data.tradingview.com/news?symbol={SYMBOL}",
            name: "Yahoo Finance",
          },
        ],
      },
      news_provider: function getNews(symbol, callback) {
        callback({
          title: "This is the title!",
          newsItems: [],
        });
      },
      load_last_chart: true,
      save_load_adapter: {
        charts: [],
        studyTemplates: [],
        drawingTemplates: [],
        chartTemplates: [],

        getAllCharts: function () {
          let charts = window.localStorage.getItem("cfd-charts");
          if (charts) {
            charts = JSON.parse(charts);
          } else {
            charts = defaultChart;
          }
          return Promise.resolve(charts);
        },

        removeChart: async function (id) {
          let charts = await this.getAllCharts();
          for (var i = 0; i < charts.length; ++i) {
            if (charts[i].id === id) {
              charts.splice(i, 1);
              window.localStorage.setItem("cfd-charts", JSON.stringify(charts));
              return Promise.resolve();
            }
          }
          return Promise.reject();
        },

        saveChart: async function (chartData) {
          if (!chartData.id) {
            chartData.id = Math.random().toString();
          } else {
            await this.removeChart(chartData.id);
          }
          let charts = await this.getAllCharts();
          charts.push(chartData);
          window.localStorage.setItem("cfd-charts", JSON.stringify(charts));
          return Promise.resolve(chartData.id);
        },

        getChartContent: async function (id) {
          let charts = await this.getAllCharts();
          for (var i = 0; i < charts.length; ++i) {
            if (charts[i].id === id) {
              return Promise.resolve(charts[i].content);
            }
          }
          return Promise.reject();
        },
      },
      loading_screen: { backgroundColor: "#000000" },

      settings_adapter: {
        initialSettings: {
          symbolWatermark:
            '{ "visibility": "true", "color": "rgba(75,79,94,0.25)" }',
        },
        setValue: function (key, value) {},
        removeValue: function (key) {},
      },
      broker_factory: function (host) {
        const brokerInstance = new Broker(host, dataFeeder);
        window.broker = brokerInstance;
        return brokerInstance;
      },
      broker_config: {
        configFlags: {
          calculatePLUsingLast: true,
          showNotificationsLog: true,
          supportAddBracketsToExistingOrder: true,
          supportClosePosition: true,
          supportConfirmations: true,
          supportEditAmount: false,
          supportExecutions: true,
          supportLeverage: false,
          supportMarketBrackets: true,
          supportModifyBrackets: true,
          supportModifyTrailingStop: false,
          supportOrderBrackets: true,
          supportOrdersHistory: true,
          supportPartialClosePosition: true,
          supportPLUpdate: true,
          supportPositions: true,
          supportPositionBrackets: true,
          supportReversePosition: false,
          supportStopLimitOrders: false,
        },
        durations: [
          { name: "GTC", value: "GTC" },
          { name: "DAY", value: "DAY" },
        ],
        positionsAlignment: "Center",
        ordersAlignment: "Center",
      },
    }));

    widget.headerReady().then(async () => {
      setIsBrokerReady(true);
      widget.createDropdown({
        title: "Change Theme",
        align: "right",
        items: [
          {
            title: "Dark",
            onSelect: () => {
              widget.changeTheme("dark");
              window.localStorage.setItem("theme", "dark");
            },
          },
          {
            title: "Light",
            onSelect: () => {
              widget.changeTheme("light");
              window.localStorage.setItem("theme", "light");
            },
          },
        ],
      });

      let watchListApi = await widget.watchList();
      const storedWatchLists = window.localStorage.getItem("cfd-watchlists");
      const watchlists = JSON.parse(storedWatchLists);
      if (watchlists) {
        const existingWatchLists = Object.values(watchListApi.getAllLists());
        for (const watchlist of Object.values(watchlists)) {
          const { id, symbols, title } = watchlist;
          const isWatchListExists = existingWatchLists.find(
            (wl) => wl.title === title
          );
          if (!isWatchListExists) {
            watchListApi.createList(title, symbols);
          } else {
            const watchlistApi = await widget.watchList();
            const activeListId = watchlistApi.getActiveListId();
            const currentListItems = watchlistApi.getList(activeListId);
            watchlistApi.updateList(activeListId, [...symbols]);
          }
        }
      }
      watchListApi = await widget.watchList();
      const active = window.localStorage.getItem("cfd-watchlist-active");
      const allLists = watchListApi.getAllLists();
      const activeList = Object.values(allLists).find(
        (list) => list.title === active
      );
      if (active) {
        watchListApi.setActiveList(activeList.id);
      }
      const updateWatchList = async () => {
        const totalLists = watchListApi.getAllLists();
        window.localStorage.setItem(
          "cfd-watchlists",
          JSON.stringify(totalLists)
        );
        const active = watchListApi.getActiveListId();
        const activeList = Object.values(totalLists).find(
          (list) => list.id === active
        );
        window.localStorage.setItem("cfd-watchlist-active", activeList.title);
      };
      const removeWatchList = (watchListId) => {
        let existingWatchLists = watchListApi.getAllLists();
        delete existingWatchLists[watchListId];
        window.localStorage.setItem(
          "cfd-watchlists",
          JSON.stringify(existingWatchLists)
        );
      };

      watchListApi.onListChanged().subscribe(null, updateWatchList);
      watchListApi.onActiveListChanged().subscribe(null, updateWatchList);
      watchListApi.onListAdded().subscribe(null, updateWatchList);
      watchListApi.onListRemoved().subscribe(null, removeWatchList);
      watchListApi.onListRenamed().subscribe(null, updateWatchList);
    });

    //Do not forget to remove the script on unmounting the component!
    return () => script.remove();
  }, []); //eslint-disable-line

  useEffect(() => {
    let charts = window.localStorage.getItem("cfd-charts");
    if (!charts) {
      window.localStorage.setItem("cfd-charts", JSON.stringify(defaultChart));
    }

    if (isBrokerReady) {
      const tvToken = localStorage.getItem("tvToken");
      const tvAccount = localStorage.getItem("tvAccount");
      const tvServer = localStorage.getItem("tvServer");

      if (tvToken) {
        setIsUserLogin(true);
        if (window.broker && window.broker.loginAccount) {
          window.broker.loginAccount(tvAccount, tvToken, tvServer);
        } else {
          console.error("Broker instance or loginAccount method not available");
        }
      } else {
        setIsDialogOpen(true);
        var iframe = document.getElementsByTagName("iframe")[0];
        var button = iframe.contentDocument.querySelector(
          'button[data-name="order-panel-button"]'
        );
        if (button) {
          button.style.display = "none";
        }
      }
    }
    // Define the callback for WebSocket close
    const onWebSocketClose = () => {
      setIsSessionModalOpen(true);
    };

    // Register the callback with dataFeeder
    dataFeeder.registerOnCloseCallback(onWebSocketClose);

    // Cleanup function to unregister the callback
    return () => dataFeeder.unregisterOnCloseCallback(onWebSocketClose);
  }, [isBrokerReady]);

  return (
    <>
      {isDialogOpen && (
        <LoginModal
          setIsDialogOpen={setIsDialogOpen}
          isDialogOpen={isDialogOpen}
          setIsUserLogin={setIsUserLogin}
        />
      )}
      {isSessionModalOpen && (
        <SessionModal
          setIsDialogOpen={setIsSessionModalOpen}
          isDialogOpen={isSessionModalOpen}
        />
      )}
      {isLogoutModalOpen && (
        <LogoutModal
          setIsDialogOpen={setIsLogoutModalOpen}
          isDialogOpen={isLogoutModalOpen}
          setIsUserLogin={setIsUserLogin}
        />
      )}
      <div className="top-bar">
        <TvLogo />
        <div>
          <a
          target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLScg-BXovBteIvbelrrAV7rInTrfypTME5rWpSGK2f9PYpks9A/viewform"
            className="btn-login btn-report"
          >
            Report an Error
          </a>

          {isUserLogin ? (
            <button
              onClick={() => setIsLogoutModalOpen(true)}
              className="btn-login"
            >
              Logout
            </button>
          ) : (
            <button onClick={() => setIsDialogOpen(true)} className="btn-login">
              Login to MT5
            </button>
          )}
        </div>
      </div>
      <div id="tv_chart_container"></div>
    </>
  );
};

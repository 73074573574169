/**
 * @module Make sure that you include Promise polyfill in your bundle to support old browsers
 * @see {@link https://caniuse.com/#search=Promise | Browsers with native Promise support}
 * @see {@link https://www.npmjs.com/package/promise-polyfill | Polyfill}
 */
import Promise from "promise-polyfill";
import $ from "jquery";
import EventSourcePolyfill from "event-source-polyfill";
import {
  ordersPageColumns,
  positionsPageColumns,
  tradingHistoryPageColumns,
  orderHistoryPageColumns,
  tradingJournal,
} from "./columns";
import { FN_API_URL, GN_API_URL } from "./const";
import {
  getSymbol,
  formatDateTimeWithTimezone,
  logErrorMessage,
  logMessage,
  getErrorMessage,
  generateUUID,
  mt5Symbols,
  transformSymbol,
  hasMinQty,
  multiply,
} from "./helper";
import { addEventListener } from "./eventDispatcher";

let isPositionCalled = false;
let isOpenOrderCalled = false;
let API_URL;

export class Broker {
  constructor(host, quotesProvider) {
    this._accountManagerData = {
      title: "Trading Sample",
      balance: "$ 0",
      equity: "$ 0",
      pl: "$ 0",
    };
    this._accountCurrency = "USD";
    this.userLoggedIn = false;
    this.userID = "";
    this.token = "";
    this._positionById = {};
    this._positions = [];
    this._orderById = {};
    this._orders = [];
    this._exchangeRate = {};
    this._positionIdAndPnlMap = new Map();
    this._quotesProvider = quotesProvider;
    this._host = host;
    this._currentUtcTimezone = "Etc/UTC";
    this._host.setButtonDropdownActions(this._buttonDropdownItems());
    const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
    if (sellBuyButtonsVisibility !== null) {
      sellBuyButtonsVisibility.subscribe(() => {
        this._host.setButtonDropdownActions(this._buttonDropdownItems());
      });
    }
    const domPanelVisibility = this._host.domPanelVisibility();
    if (domPanelVisibility) {
      domPanelVisibility.subscribe(() => {
        this._host.setButtonDropdownActions(this._buttonDropdownItems());
      });
    }
    const orderPanelVisibility = this._host.orderPanelVisibility();
    if (orderPanelVisibility) {
      orderPanelVisibility.subscribe(() => {
        this._host.setButtonDropdownActions(this._buttonDropdownItems());
      });
    }
    this._amChangeDelegate = this._host.factory.createDelegate();
    this._balanceValue = this._host.factory.createWatchedValue(
      this._accountManagerData.balance
    );
    this._equityValue = this._host.factory.createWatchedValue(
      this._accountManagerData.equity
    );
    this._plValue = this._host.factory.createWatchedValue(
      this._accountManagerData.pl
    );

    this._ahChangeDelegate = this._host.factory.createDelegate();
  }

  _logoutAccountWhenForbidden() {
    localStorage.removeItem("tvToken");
    this.logoutAccount();
    window.location.reload();
  }

  _updateAccountInfo() {
    let pnl = 0;
    for (const [, profitOrLoss] of this._positionIdAndPnlMap) {
      pnl += profitOrLoss;
    }
    this._accountManagerData.pl = pnl.toFixed(2);
    this._accountManagerData.equity = (
      parseFloat(this._accountManagerData.balance) + pnl
    ).toFixed(2);
    this._balanceValue.setValue(
      `$ ${parseFloat(this._accountManagerData.balance).toFixed(2)}`
    );
    this._equityValue.setValue(`$ ${this._accountManagerData.equity}`);
    this._plValue.setValue(`$ ${this._accountManagerData.pl}`);
    this._host.equityUpdate(this._accountManagerData.equity);
  }

  _createOrUpdateOrder(order) {
    this._orderById[order.id] = order;
    const oIndex = this._orders.findIndex((o) => o.id === order.id);
    if (oIndex !== -1) {
      this._orders.splice(oIndex, 1, order);
    } else {
      this._orders.push(order);
    }
    this._host.orderUpdate(order);
  }

  _createOrUpdateBracketOrder(
    id,
    symbol,
    qty,
    side,
    stopLoss,
    takeProfit,
    status,
    createTime,
    fillPrice,
    closingTime
  ) {
    let stopOrder = this._orderById[`${id}s`];
    if (stopOrder) {
      if (stopLoss) {
        if (fillPrice) {
          stopOrder.fillPrice = fillPrice;
          stopOrder.price = fillPrice;
          stopOrder.closingTime = closingTime;
          stopOrder.status = 2;
        } else {
          stopOrder.qty = qty;
          stopOrder.stopPrice = stopLoss;
          stopOrder.price = stopLoss;
          stopOrder.status = status;
        }
      } else {
        stopOrder.status = 1;
      }
    } else if (stopLoss) {
      stopOrder = {
        id: `${id}s`,
        symbol: symbol,
        qty: qty,
        side: side === 1 ? -1 : 1,
        type: 3,
        stopPrice: stopLoss,
        // stopLoss,
        price: stopLoss,
        status: status,
        parentType: 2,
        parentId: String(id),
        createTime: createTime,
      };
    }
    if (stopOrder) {
      this._createOrUpdateOrder(stopOrder);
    }
    let limitOrder = this._orderById[`${id}t`];
    if (limitOrder) {
      if (takeProfit) {
        if (fillPrice) {
          limitOrder.fillPrice = fillPrice;
          limitOrder.price = fillPrice;
          limitOrder.closingTime = closingTime;
          limitOrder.status = 2;
        } else {
          limitOrder.qty = qty;
          limitOrder.limitPrice = takeProfit;
          limitOrder.price = takeProfit;
          limitOrder.status = status;
        }
      } else {
        limitOrder.status = 1;
      }
    } else if (takeProfit) {
      limitOrder = {
        id: `${id}t`,
        symbol: symbol,
        qty: qty,
        side: side === 1 ? -1 : 1,
        type: 1,
        limitPrice: takeProfit,
        // takeProfit,
        price: takeProfit,
        status: status,
        parentType: 2,
        parentId: String(id),
        createTime: createTime,
      };
    }
    if (limitOrder) {
      this._createOrUpdateOrder(limitOrder);
    }
  }

  _createOrUpdatePosition(position) {
    if (this._positionById[position.id]) {
      const sPosition = this._positionById[position.id];
      sPosition.qty = position.qty;
      sPosition.takeProfit = position.takeProfit;
      sPosition.stopLoss = position.stopLoss;
      sPosition.updateTime = position.updateTime;
      this._host.positionUpdate(sPosition);
      this._host.plUpdate(sPosition.pnl);
      this._host.showNotification(
        `Order ${position.id} placed`,
        `${position.side == 1 ? "BUY" : "SELL"} ${position.qty} ${
          position.symbol
        } at market`,
        1
      );
    } else {
      this._positionById[position.id] = position;
      if (this._orderById[position.id]) {
        const order = this._orderById[position.id];
        order.status = 2;
        this._createOrUpdateOrder(order);
      }
      this._host.positionUpdate(position);
      this._host.plUpdate(position.pnl);
      this._host.showNotification(
        `Order ${position.id} placed`,
        `${position.side == 1 ? "BUY" : "SELL"} ${position.qty} ${
          position.symbol
        } at market`,
        1
      );

      logMessage(
        "_createOrUpdatePosition: subscribeQuotes started for position ",
        position.id
      );
      const symbolInfo = getSymbol(position.symbol);
      const subscribe = new Promise((resolve) => {
        this._quotesProvider.subscribeQuotes(
          [position.symbol],
          [position.symbol],
          (quote) =>
            this._calculatePnlAndSetAccountInformation(
              quote,
              position.id,
              symbolInfo
            ),
          `_position_${position.id}`
        );
        resolve();
      });
    }
    this._createOrUpdateBracketOrder(
      position.id,
      position.symbol,
      position.qty,
      position.side,
      position.stopLoss,
      position.takeProfit,
      6,
      position.createTime
    );
  }

  _calculatePnlAndSetAccountInformation(quote, positionId, symbolInfo) {
    const position = this._positionById[positionId];
    const quoteValue = quote[0].v;
    position.last = position.side === 1 ? quoteValue.bid : quoteValue.ask;
    position.pnl =
      (position.last - position.avgPrice) *
      symbolInfo.contract_size *
      position.qty *
      position.side;

    const currencyConversionNeeded = !position.symbol.includes("/USD");

    if (currencyConversionNeeded) {
      // Extract currency code for conversion
      const currencyCode = position.symbol.slice(-3);

      // Get the conversion rate based on the currency code
      const conversionRate = this._exchangeRate?.[currencyCode];

      if (conversionRate) {
        position.conversionRate = 1 / conversionRate;
        // Convert pnl to "USD"
        position.pnl = position.pnl * position.conversionRate;
      }
    }

    position.pnl = Number(position.pnl);

    if (
      this._positionIdAndPnlMap.get(positionId) !== position.pnl ||
      this._positionIdAndPnlMap.get(positionId) === undefined
    ) {
      this._positionIdAndPnlMap.set(positionId, position.pnl);
      this._host.positionPartialUpdate(positionId, {
        pnl: position.pnl,
        last: position.last,
      });
      this._host.plUpdate(position.pnl);
      this._updateAccountInfo();
    }
  }

  _deletePosition(position, sqty) {
    const sPosition = this._positionById[position.id];
    if (sPosition) {
      const searchString = `${position.id}`;
      for (const key in this._orderById) {
        if (key.includes(searchString)) {
          delete this._orderById[key];
        }
      }

      this._createOrUpdateBracketOrder(
        position.id,
        position.symbol,
        position.qty,
        position.side,
        position.stopLoss,
        position.takeProfit,
        1,
        position.createTime
      );

      this._orders = this._orders.filter(
        (order) => !order.id.includes(position.id)
      );

      delete this._positionById[sPosition.id];
      this._positions = this._positions.filter((cp) => cp.id != position.id);
      this._host.positionUpdate(position);
      this._host.showNotification(
        `Order ${position.id} placed`,
        `${position.side == 1 ? "SELL" : "BUY"} ${sqty} ${
          position.symbol
        } at market`,
        1
      );
      const subscribe = new Promise((resolve) => {
        this._quotesProvider.unsubscribeQuotes(`_position_${position.id}`);
        logMessage("_deletePosition: unsubscribeQuotes for ", position.id);
        resolve();
      });
      this._positionIdAndPnlMap.delete(position.id);
      this._accountManagerData.balance =
        parseFloat(this._accountManagerData.balance) + position.pnl;
      this._updateAccountInfo();
    }
  }

  _createOrUpdateExecution(execution) {
    this._host.executionUpdate(execution);
  }

  async _fetchOpenOrders() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(
        `${API_URL}/accounts/${this.userID}/orders/open`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
            Authorization: `${this.token}`,
          },
        }
      );
      if (res.ok) {
        let orders = await res.json();
        orders = orders.d;
        orders.map((order) => {
          order = this._convertOrderToTVOrder(order);
          this._orderById[order.id] = order;
        });
        this._orders = orders;
        logMessage("_fetchOpenOrders: Orders => ", orders);
      } else if (!res.ok && res.status === 401) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        "_fetchOpenOrders: Error occured while fetching orders ",
        e
      );
    }
  }

  async _fetchClosedOrders() {
    return new Promise(async (resolve, reject) => {
      if (!this.userLoggedIn) return resolve([]);
      try {
        const res = await fetch(
          `${API_URL}/accounts/${this.userID}/orders/closed`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Credentials": "true",
              Authorization: `${this.token}`,
            },
          }
        );
        if (res.ok) {
          let orders = await res.json();
          orders = orders.d;
          orders.map((order) => {
            order = this._convertOrderToTVOrder(order, true);
          });
          logMessage("_fetchClosedOrders: Orders => ", orders);
          resolve(orders);
        } else if (!res.ok && res.status === 401) {
          this._logoutAccountWhenForbidden();
        }
      } catch (e) {
        logErrorMessage(
          "_fetchClosedOrders: Error occured while fetching orders ",
          e
        );
        reject(e);
      }
    });
  }

  async _fetchPositions() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(`${API_URL}/accounts/${this.userID}/positions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `${this.token}`,
        },
      });

      if (res.ok) {
        let positions = await res.json();
        positions = positions.d;
        positions.map((position) => {
          position = this._convertPositionToTVPosition(position);
          this._positionById[position.id] = position;
          const symbolInfo = getSymbol(position.symbol);
          const subscribe = new Promise((resolve) => {
            this._quotesProvider.subscribeQuotes(
              [position.symbol],
              [position.symbol],
              (quote) =>
                this._calculatePnlAndSetAccountInformation(
                  quote,
                  position.id,
                  symbolInfo
                ),
              `_position_${position.id}`
            );
            resolve();
          });
        });
        this._positions = positions;
        logMessage("_fetchPositions: Positions => ", positions);
      } else if (!res.ok && res.status === 401) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        "_fetchPositions: Error occured while fetching positions ",
        e
      );
    }
  }

  _fetchExecutions(symbol) {
    return new Promise(async (resolve, reject) => {
      if (!this.userLoggedIn) return resolve([]);
      try {
        const res = await fetch(
          `${API_URL}/accounts/${
            this.userID
          }/executions?symbol=${transformSymbol(symbol)}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Credentials": "true",
              Authorization: `${this.token}`,
            },
          }
        );
        if (res.ok) {
          let executions = await res.json();
          executions = executions.d;
          executions.map((execution) => {
            execution = this._convertExecutionToTVExecution(execution);
          });
          logMessage("_fetchExecutions: Executions => ", executions);
          resolve(executions);
        } else if (!res.ok && res.status === 401) {
          this._logoutAccountWhenForbidden();
        }
      } catch (e) {
        logErrorMessage(
          "_fetchExecutions: Error occured while fetching executions ",
          e
        );
        reject(e);
      }
    });
  }

  async _fetchAccountInfo() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(`${API_URL}/accounts/${this.userID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `${this.token}`,
        },
      });
      if (res.ok) {
        let accountInfo = await res.json();
        this._accountManagerData.balance = accountInfo.d.balance;
        this._accountManagerData.pl = accountInfo.d.pnl;
        this._accountManagerData.equity = accountInfo.d.equity;
        this._updateAccountInfo();
      } else if (!res.ok && res.status === 401) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        "fetchAccountInfo: Error occured while fetching account info ",
        e
      );
    }
  }

  _fetchTradingHistory() {
    return new Promise(async (resolve, reject) => {
      if (!this.userLoggedIn) return resolve([]);
      fetch(`${API_URL}/accounts/${this.userID}/history`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `${this.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.ok && res.status === 401) {
            this._logoutAccountWhenForbidden();
            reject(res.errmsg);
          } else {
            const histories = res.d;
            histories.map((history) => {
              history = this._convertAccountHistoryToTVAccountHistory(history);
            });
            logMessage("_fetchTradingHistory: Account history => ", histories);
            resolve(histories);
          }
        })
        .catch((e) => {
          logErrorMessage(`_fetchTradingHistory: ${getErrorMessage(e)}`, e);
          reject(e);
        });
    });
  }

  _fetchTradingJournal() {
    return new Promise(async (resolve, reject) => {
      if (!this.userLoggedIn) return resolve([]);
      fetch(`${API_URL}/accounts/${this.userID}/logs`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `${this.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.ok && res.status === 401) {
            this._logoutAccountWhenForbidden();
            reject(res.errmsg);
          } else {
            logMessage("_fetchTradingJournal: Account journal => ", res.d);
            const journalData = res.d;
            journalData.map((journal) => {
              journal = this._convertJournal(journal);
            });
            resolve(journalData);
          }
        })
        .catch((e) => {
          logErrorMessage(`_fetchTradingJournal: ${getErrorMessage(e)}`, e);
          reject(e);
        });
    });
  }

  connectionStatus() {
    return this.userLoggedIn ? 1 : 0;
  }

  chartContextMenuActions(context, options) {
    return this._host.defaultContextMenuActions(context);
  }

  isTradable(symbol) {
    return Promise.resolve(true);
  }

  placeOrder(preOrder) {
    if (!this.userLoggedIn) return {};
    return new Promise((resolve, reject) => {
      logMessage("placeOrder: Place Order =>", preOrder);
      this._host.activateBottomWidget();
      let orderBody = {
        symbol: transformSymbol(preOrder.symbol),
        qty: String(preOrder.qty * 10000),
        side: preOrder.side === -1 ? "sell" : "buy",
        type:
          preOrder.type == 1 ? "limit" : preOrder.type == 3 ? "stop" : "market",
        price: String(preOrder.seenPrice),
        limitPrice: preOrder.limitPrice
          ? String(preOrder.limitPrice)
          : undefined,
        stopPrice: preOrder.stopPrice ? String(preOrder.stopPrice) : undefined,
        durationType:
          preOrder.duration && preOrder.duration.type
            ? preOrder.duration.type
            : undefined,
        durationDateTime:
          preOrder.duration && preOrder.duration.datetime
            ? preOrder.duration.datetime
            : undefined,
        stopLoss: preOrder.stopLoss ? String(preOrder.stopLoss) : undefined,
        takeProfit: preOrder.takeProfit
          ? String(preOrder.takeProfit)
          : undefined,
      };
      fetch(`${API_URL}/accounts/${this.userID}/create_order`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
          Authorization: `${this.token}`,
        },
        body: JSON.stringify(orderBody),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.s && res.s === "error") {
            reject(res.message);
          } else {
            resolve(res.d);
          }
        })
        .catch((e) => {
          logErrorMessage(`placeOrder: ${getErrorMessage(e)}`, e);
          reject(e);
        });
    });
  }

  modifyOrder(order) {
    if (!this.userLoggedIn) return;
    return new Promise((resolve, reject) => {
      logMessage("modifyOrder: Update order => ", order);
      let orderId = order.id;
      let originalOrder = this._orderById[orderId];
      let url;
      let body;
      const isSLOrder = orderId.indexOf("s") > 0;
      const isTPOrder = orderId.indexOf("t") > 0;
      if (isSLOrder || isTPOrder) {
        orderId = orderId.substr(0, orderId.length - 1);
        const position = this._positionById[orderId];
        if (position) {
          const stopLoss = isTPOrder
            ? position.stopLoss
            : order.stopPrice
            ? order.stopPrice
            : undefined;
          const takeProfit = isSLOrder
            ? position.takeProfit
            : order.limitPrice
            ? order.limitPrice
            : undefined;
          url = `${API_URL}/accounts/${this.userID}/positions/${position.id}`;
          body = {
            symbol: transformSymbol(position.symbol),
            stopLoss: stopLoss ? String(stopLoss) : null,
            takeProfit: takeProfit ? String(takeProfit) : null,
          };
          logMessage("modifyOrder: Modify position => ", body);
        } else {
          originalOrder = this._orderById[orderId];
          if (originalOrder && originalOrder.status === 6) {
            url = `${API_URL}/accounts/${this.userID}/orders/${orderId}`;
            body = {
              qty: String(originalOrder.qty * 10000),
            };
            if (isSLOrder) {
              body.stopLoss = order.limitPrice
                ? String(order.limitPrice)
                : order.stopLoss
                ? String(order.stopLoss)
                : null;
            }
            if (isTPOrder) {
              body.takeProfit = order.limitPrice
                ? String(order.limitPrice)
                : order.takeProfit
                ? String(order.takeProfit)
                : null;
            }
            logMessage("modifyOrder: Modify order => ", body);
          }
        }
      } else if (originalOrder && originalOrder.status === 6) {
        url = `${API_URL}/accounts/${this.userID}/orders/${orderId}`;
        body = {
          qty: String(order.qty * 10000),
          limitPrice: order.limitPrice ? String(order.limitPrice) : null,
          stopPrice: order.stopPrice ? String(order.stopPrice) : null,
          durationType: order.duration.type,
          durationDateTime: order.duration.datetime,
          stopLoss: order.stopLoss ? String(order.stopLoss) : null,
          takeProfit: order.takeProfit ? String(order.takeProfit) : null,
        };
        logMessage("modifyOrder: Modify order => ", body);
      }
      if (body) {
        fetch(url, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
            Authorization: `${this.token}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.s && res.s === "error") {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`modifyOrder: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      } else {
        resolve();
      }
    });
  }

  editPositionBrackets(positionId, positionBrackets) {
    return new Promise((resolve, reject) => {
      logMessage("editPositionBrackets: Edit position", positionBrackets);
      const position = this._positionById[positionId];
      if (position) {
        const positionBody = {
          symbol: transformSymbol(position.symbol),
          stopLoss: positionBrackets.stopLoss
            ? String(positionBrackets.stopLoss)
            : null,
          takeProfit: positionBrackets.takeProfit
            ? String(positionBrackets.takeProfit)
            : null,
        };
        fetch(`${API_URL}/accounts/${this.userID}/positions/${positionId}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
            Authorization: `${this.token}`,
          },
          body: JSON.stringify(positionBody),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.s && res.s === "error") {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`editPositionBrackets: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      } else {
        resolve();
      }
    });
  }

  closePosition(positionId, qty=0) {
    return new Promise((resolve, reject) => {
      const position = this._positionById[positionId];
      if (position) {
        const { isValidQty, message } = hasMinQty(qty);
        if (!isValidQty) {
          reject(message);
          return;
        }
        fetch(`${API_URL}/accounts/${this.userID}/positions/${positionId}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
            Authorization: `${this.token}`,
          },
          body: JSON.stringify({ qty: String(qty * 10000) }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.s && res.s === "error") {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`closePosition: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      } else {
        resolve();
      }
    });
  }

  async orders() {
    logMessage("orders: Get orders");

    if (!isOpenOrderCalled) {
      isOpenOrderCalled = true;
      await this._fetchOpenOrders();
    }
    return Promise.resolve(this._orders.slice());
  }

  async ordersHistory() {
    logMessage("ordersHistory: Get orders history");
    return this._fetchClosedOrders();
  }

  async positions() {
    logMessage("positions: Get positions");
    if (!isPositionCalled) {
      isPositionCalled = true;
      await this._fetchPositions();
    }
    return Promise.resolve(this._positions.slice());
  }

  async executions(symbol) {
    logMessage(`executions: Get executions for symbol ${symbol}`);
    return this._fetchExecutions(symbol);
  }

  async cancelOrder(orderId) {
    return new Promise((resolve, reject) => {
      const isSLOrder = orderId.indexOf("s") > 0;
      const isTPOrder = orderId.indexOf("t") > 0;
      if (isSLOrder || isTPOrder) {
        this.modifyOrder({
          id: orderId,
        })
          .then(() => resolve())
          .catch((e) => reject(e));
      } else {
        fetch(`${API_URL}/accounts/${this.userID}/orders/${orderId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
            Authorization: `${this.token}`,
          },
          method: "DELETE",
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.s && res.s === "error") {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`cancelOrder: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      }
    });
  }

  accountManagerInfo() {
    const summaryProps = [
      {
        text: "Account Balance",
        wValue: this._balanceValue,
        formatter: "text" /* StandardFormatterName.Fixed */,
        isDefault: true,
      },
      {
        text: "Equity",
        wValue: this._equityValue,
        formatter: "text" /* StandardFormatterName.Fixed */,
        isDefault: true,
      },
      {
        text: "P&L",
        wValue: this._plValue,
        formatter: "text" /* StandardFormatterName.Fixed */,
        isDefault: true,
      },
    ];
    return {
      accountTitle: "Trading Sample",
      summary: summaryProps,
      orderColumns: ordersPageColumns,
      positionColumns: positionsPageColumns,
      historyColumns: orderHistoryPageColumns,
      pages: [
        {
          id: "tradingHistory",
          title: "Trading History",
          tables: [
            {
              id: "history",
              columns: tradingHistoryPageColumns,
              getData: () => {
                return this._fetchTradingHistory();
              },
              initialSorting: {
                property: "closeTime",
                asc: false,
              },
              changeDelegate: this._ahChangeDelegate,
            },
          ],
        },
        /*{
          id: "tradingJournal",
          title: "Trading Journal",
          tables: [
            {
              id: "tradingJournal",
              columns: tradingJournal,
              getData: () => {
                return this._fetchTradingJournal();
              },
              initialSorting: {
                property: "id",
                asc: true,
              },
              changeDelegate: this._ahChangeDelegate,
            },
          ],
        },*/
      ],
      contextMenuActions: (contextMenuEvent, activePageActions) => {
        return Promise.resolve(this._bottomContextMenuItems(activePageActions));
      },
    };
  }

  async symbolInfo(symbol) {
    const sym = getSymbol(symbol);
    const isForex = sym && sym.type === "forex";
    const mintick = await this._host.getSymbolMinTick(symbol);
    const pipSize = isForex
      ? Math.max(this._pipSizeForForex(symbol), mintick)
      : mintick; // pip size can differ from minTick
    const pointValue = 1; // USD value of 1 point of price
    const accountCurrencyRate =
      pointValue / (this._exchangeRate[sym.currency_code] || 1); // account currency rate
    let qty = {
      max: 1e3,
      min: 0.01,
      step: 0.01,
      uiStep: 0.01,
      default: 0.01,
    };
    const symbolInfo = {
      qty,
      pipValue: pipSize * pointValue * accountCurrencyRate || 1,
      pipSize: pipSize,
      minTick: mintick,
      lotSize: sym.contract_size,
      description: sym ? sym.description : "",
      type: sym ? sym.type : "",
      currency: this._accountCurrency,
    };
    logMessage(`symbolInfo: ${symbol} => `, symbolInfo);
    return symbolInfo;
  }

  _pipSizeForForex(symbol) {
    return symbol.indexOf("JPY") === symbol.length - 3 ? 0.01 : 1e-4;
  }

  currentAccount() {
    return this.userID;
  }

  async accountsMetainfo() {
    return [
      {
        id: this.userID,
        name: this.userID,
        currencySign: "$",
        currency: this._accountCurrency,
      },
    ];
  }

  _bottomContextMenuItems(activePageActions) {
    const separator = { separator: true };
    const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
    if (activePageActions.length) {
      activePageActions.push(separator);
    }
    return activePageActions.concat([
      {
        text: "Show Buy/Sell Buttons",
        action: () => {
          if (sellBuyButtonsVisibility) {
            sellBuyButtonsVisibility.setValue(
              !sellBuyButtonsVisibility.value()
            );
          }
        },
        checkable: true,
        checked:
          sellBuyButtonsVisibility !== null && sellBuyButtonsVisibility.value(),
      },
      {
        text: "Trading Settings...",
        action: () => {
          this._host.showTradingProperties();
        },
      },
    ]);
  }

  _buttonDropdownItems() {
    const defaultActions = this._host.defaultDropdownMenuActions();
    return defaultActions.concat([
      {
        text: "Trading Settings...",
        action: () => {
          this._host.showTradingProperties();
        },
      },
    ]);
  }

  _convertOrderToTVOrder(order, keepSymbol = false) {
    order.side = order.side === "buy" ? 1 : -1;

    const isSLOrder = order.id.indexOf("s") > 0;
    const isTPOrder = order.id.indexOf("t") > 0;

    if (isSLOrder && isTPOrder) {
      order.limitPrice = order.price;
    } else if (isSLOrder) {
      order.limitPrice = order.stopLoss;
      order.price = order.stopLoss;
      order.stopLoss = undefined;
    } else if (isTPOrder) {
      order.limitPrice = order.takeProfit;
      order.price = order.takeProfit;
      order.takeProfit = undefined;
    } else {
      order.limitPrice = order.price;
      if (order.type == "stop") {
        order.stopPrice = order.price;
      }
    }

    if (order.type === "limit") {
      order.type = 1;
    } else if (order.type === "market") order.type = 2;
    else if (order.type === "stop") order.type = 3;
    if (order.status === "cancelled") {
      order.status = 1;
      if (order.type === 1) {
        order.price = order.limitPrice;
      } else if (order.type === 3) {
        order.price = order.stopPrice;
      }
    } else if (order.status === "filled") {
      order.status = 2;
      // limit
      if (order.type === 1) {
        order.fillPrice = order.limitPrice;
      }
      // market
      else if (order.type === 2) {
        order.fillPrice = order.price;
      }
      // stop
      else if (order.type === 3) {
        order.fillPrice = order.stopPrice;
      }
    } else if (order.status === "inactive") order.status = 3;
    else if (order.status === "placing") order.status = 4;
    else if (order.status === "rejected") order.status = 5;
    else if (order.status === "working") order.status = 6;
    if (order.durationType) {
      order.duration = {
        type: order.durationType,
      };
      delete order.durationType;
    }
    if (order.createTime) {
      order.createTime = formatDateTimeWithTimezone(
        order.createTime,
        this._currentUtcTimezone
      );
    }
    if (order.closingTime) {
      order.closingTime = formatDateTimeWithTimezone(
        order.closingTime,
        this._currentUtcTimezone
      );
    }
    const symbol = mt5Symbols[order.symbol];
    if (symbol || !keepSymbol) {
      order.symbol = symbol;
    }
    order.qty /= 10000;
    if (order.commission !== undefined) {
      order.commission = `$ ${parseFloat(order.commission).toFixed(2)}`;
    }
    return order;
  }

  _convertPositionToTVPosition(position) {
    if (position.side === "buy") position.side = 1;
    else if (position.side === "sell") position.side = -1;
    position.createTime = formatDateTimeWithTimezone(
      position.createTime,
      this._currentUtcTimezone
    );
    position.avgPrice = position.price;
    if (position.commission !== undefined) {
      position.commission = `$ ${parseFloat(position.commission).toFixed(2)}`;
    }
    position.symbol = mt5Symbols[position.symbol];
    position.qty /= 10000;
    return position;
  }

  _convertExecutionToTVExecution(execution) {
    if (execution.side === "buy") execution.side = 1;
    else if (execution.side === "sell") execution.side = -1;
    execution.time = new Date(execution.time).getTime();
    execution.symbol = mt5Symbols[execution.symbol];
    execution.qty /= 10000;
    return execution;
  }

  _convertAccountHistoryToTVAccountHistory(accountHistory) {
    if (accountHistory.symbol) {
      accountHistory.symbol = mt5Symbols[accountHistory.symbol];
    } else {
      accountHistory.symbol = "";
    }
    if (accountHistory.commission !== undefined) {
      accountHistory.commission = `$ ${parseFloat(
        accountHistory.commission
      ).toFixed(2)}`;
    }
    if (accountHistory.qty) {
      accountHistory.qty /= 10000;
    }
    accountHistory.openTime = formatDateTimeWithTimezone(
      accountHistory.openTime,
      this._currentUtcTimezone
    );
    accountHistory.closeTime = formatDateTimeWithTimezone(
      accountHistory.closeTime,
      this._currentUtcTimezone
    );
    return accountHistory;
  }

  _convertJournal(journal) {
    journal.time = formatDateTimeWithTimezone(
      journal.time,
      this._currentUtcTimezone
    );
    journal.text = journal.text;

    return journal;
  }

  async _fetchExchangeRate() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(
        `${API_URL}/accounts/${this.userID}/exchange-rate`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
            Authorization: `${this.token}`,
          },
        }
      );
      if (res.ok) {
        let data = await res.json();
        this._exchangeRate = data.d;
        logMessage("_fetchExchangeRate: ExchangeRate => ", this._exchangeRate);
      } else if (!res.ok && res.status === 401) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        "_fetchExchangeRate: Error occured while fetching exchangeRate ",
        e
      );
    }
  }

  _connectSSE() {
    if (!this.userLoggedIn) return;
    try {
      setTimeout(() => {
        this._quotesProvider.subscribeEvents(this.userID, this.token);
      }, 2000);

      addEventListener("position", (data) => {
        let position = this._convertPositionToTVPosition(data);
        const sQty = position.qty;
        if (position.isClose == true) {
          position.qty = 0;
          this._deletePosition(position, sQty);
        } else {
          this._createOrUpdatePosition(position);
        }
      });
      addEventListener("execution", (data) => {
        const execution = this._convertExecutionToTVExecution(data);
        this._createOrUpdateExecution(execution);
      });
      addEventListener("order", (data) => {
        const order = this._convertOrderToTVOrder(data);
        this._createOrUpdateOrder(order);
        if (order.status === 1 || order.status === 2 || order.status === 6) {
          let stopLoss = undefined;
          let takeProfit = undefined;
          let updateBracketOrders = true;
          if (order.status !== 1) {
            if (order.status === 6) {
              stopLoss = order.stopLoss;
              takeProfit = order.takeProfit;
            } else if (order.status === 2) {
              stopLoss = order.reason === "sl" ? order.price : undefined;
              takeProfit = order.reason === "tp" ? order.price : undefined;
              if (!order.reason) {
                const position = this._positionById[order.positionId];
                if (position) {
                  updateBracketOrders = false;
                } else {
                  stopLoss = order.stopLoss;
                  takeProfit = order.takeProfit;
                }
              }
            }
          }
          if (updateBracketOrders) {
            this._createOrUpdateBracketOrder(
              order.positionId ? order.positionId : order.id,
              order.symbol,
              order.qty,
              order.side,
              stopLoss,
              takeProfit,
              order.status === 6 ? 3 : 6,
              order.createTime,
              order.reason ? order.price : undefined,
              order.closingTime
            );
          }
        }
      });
      addEventListener("accountHistory", (data) => {
        const accountHistory =
          this._convertAccountHistoryToTVAccountHistory(data);
        this._ahChangeDelegate.fire(accountHistory);
      });
    } catch (e) {
      logErrorMessage("_connectSSE: error occured while connecting sse", e);
    }
  }

  _disconnectSSE() {
    for (const [positionId, pnl] of this._positionIdAndPnlMap) {
      const subscribe = new Promise((resolve) => {
        this._quotesProvider.unsubscribeQuotes(`_position_${positionId}`);
        resolve();
      });
    }
    this._positionIdAndPnlMap = new Map();
  }

  loginAccount(userID, token, tvServer) {
    this.userLoggedIn = true;
    this.userID = userID;
    if (tvServer == "gn") {
      API_URL = GN_API_URL;
    } else {
      API_URL = FN_API_URL;
    }
    this.token = token;
    this.sessionId = generateUUID();
    this._fetchExchangeRate();
    this._fetchAccountInfo();
    this._connectSSE();
    this._host._trading.toggleTradingWidget();
    this._host.connectionStatusUpdate(1);
    this._host.currentAccountUpdate();
  }

  logoutAccount() {
    this.userLoggedIn = false;
    this.userID = "";
    this.token = "";
    this._disconnectSSE();
    this._host._trading.toggleMinimizeBottomWidgetBar();
    this._host.connectionStatusUpdate(0);
    this._balanceValue.setValue(0);
    this._equityValue.setValue(0);
    this._plValue.setValue(0);
    this._host.currentAccountUpdate();
  }

  getHost() {
    return this._host;
  }
}

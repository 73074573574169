export const LoadingButton = () => {
  return (
    <svg
      style={{ marginTop: "-4px" }}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="10"
        fill="none"
        strokeWidth="5"
        stroke="white"
        strokeLinecap="round"
      >
        <animate
          attributeName="stroke-dashoffset"
          dur="2s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="0;256.58892822265625"
        ></animate>
      </circle>
    </svg>
  );
};

export const forex_symbols = {
  'AUDSGD': {
    symbol: 'AUDSGD',
    full_name: 'AUDSGD',
    type: 'forex',
    description: 'Australian dollar - Singapore dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'SGD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  'USDCNH': {
    symbol: 'USDCNH',
    full_name: 'USDCNH',
    type: 'forex',
    description: 'US Dollar vs Chinese Yuan',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CNH',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/CN.svg',
    ],
  },
  'USDDKK': {
    symbol: 'USDDKK',
    full_name: 'USDDKK',
    type: 'forex',
    description: 'US Dollar vs Danish Krone',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'DKK',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/DK.svg',
    ],
  },
  'EURUSD': {
    symbol: 'EURUSD',
    full_name: 'EURUSD',
    type: 'forex',
    description: 'Euro vs US Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'EURJPY': {
    symbol: 'EURJPY',
    full_name: 'EURJPY',
    type: 'forex',
    description: 'Euro vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'AUDNZD': {
    symbol: 'AUDNZD',
    full_name: 'AUDNZD',
    type: 'forex',
    description: 'Australian Dollar vs Singapore Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'NZD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
    ],
  },
  'USDSGD': {
    symbol: 'USDSGD',
    full_name: 'USDSGD',
    type: 'forex',
    description: 'US Dollar vs Singapore Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  'EURHKD': {
    symbol: 'EURHKD',
    full_name: 'EURHKD',
    type: 'forex',
    description: 'Euro vs Hong Kong Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'HKD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  'EURCHF': {
    symbol: 'EURCHF',
    full_name: 'EURCHF',
    type: 'forex',
    description: 'Euro vs Swiss Franc',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CHF',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  'USDNOK': {
    symbol: 'USDNOK',
    full_name: 'USDNOK',
    type: 'forex',
    description: 'US Dollar vs Norwegian Krone',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    currency_code: 'NOK',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'NZDSGD': {
    symbol: 'NZDSGD',
    full_name: 'NZDSGD',
    type: 'forex',
    description: 'New Zealand Dollar vs Singapore Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    currency_code: 'SGD',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  'GBPSGD': {
    symbol: 'GBPSGD',
    full_name: 'GBPSGD',
    type: 'forex',
    description: 'Pound Sterling vs Singapore Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'SGD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  'EURHUF': {
    symbol: 'EURHUF',
    full_name: 'EURHUF',
    type: 'forex',
    description: 'Euro vs Hungarian Forint',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'HUF',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/HU.svg',
    ],
  },
  'EURCAD': {
    symbol: 'EURCAD',
    full_name: 'EURCAD',
    type: 'forex',
    description: 'Euro vs Canadian Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CAD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  'GBPNZD': {
    symbol: 'GBPNZD',
    full_name: 'GBPNZD',
    type: 'forex',
    description: 'Pound Sterling vs New Zealand Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'NZD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
    ],
  },
  'EURTRY': {
    symbol: 'EURTRY',
    full_name: 'EURTRY',
    type: 'forex',
    description: 'Euro vs Turkish Lira',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'TRY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/TR.svg',
    ],
  },
  'AUDUSD': {
    symbol: 'AUDUSD',
    full_name: 'AUDUSD',
    type: 'forex',
    description: 'Australian Dollar vs US Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'AUDJPY': {
    symbol: 'AUDJPY',
    full_name: 'AUDJPY',
    type: 'forex',
    description: 'Australian Dollar vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'CHFJPY': {
    symbol: 'CHFJPY',
    full_name: 'CHFJPY',
    type: 'forex',
    description: 'Swiss Franc vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'AUDCHF': {
    symbol: 'AUDCHF',
    full_name: 'AUDCHF',
    type: 'forex',
    description: 'Australian Dollar vs Swiss Franc',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    currency_code: 'CHF',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  'USDJPY': {
    symbol: 'USDJPY',
    full_name: 'USDJPY',
    type: 'forex',
    description: 'United States dollar - Japanese yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'GBPAUD': {
    symbol: 'GBPAUD',
    full_name: 'GBPAUD',
    type: 'forex',
    description: 'Pound Sterling vs Australian Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'AUD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  'USDHKD': {
    symbol: 'USDHKD',
    full_name: 'USDHKD',
    type: 'forex',
    description: 'United States dollar - Hong Kong dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'HKD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/HK.svg',
    ],
  },
  'USDCHF': {
    symbol: 'USDCHF',
    full_name: 'USDCHF',
    type: 'forex',
    description: 'US Dollar vs Swiss Franc',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CHF',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  'ZARJPY': {
    symbol: 'ZARJPY',
    full_name: 'ZARJPY',
    type: 'forex',
    description: 'South African Rand vs Japanese Yen ',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/ZA.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'USDMXN': {
    symbol: 'USDMXN',
    full_name: 'USDMXN',
    type: 'forex',
    description: 'US Dollar vs Mexican Peso',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'MXN',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/MX.svg',
    ],
  },
  'NZDUSD': {
    symbol: 'NZDUSD',
    full_name: 'NZDUSD',
    type: 'forex',
    description: 'New Zealand Dollar vs US Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'NZDJPY': {
    symbol: 'NZDJPY',
    full_name: 'NZDJPY',
    type: 'forex',
    description: 'New Zealand Dollar vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    contract_size:100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'AUDCAD': {
    symbol: 'AUDCAD',
    full_name: 'AUDCAD',
    type: 'forex',
    description: 'Australian Dollar vs Canadian Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CAD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  'NOKJPY': {
    symbol: 'NOKJPY',
    full_name: 'NOKJPY',
    type: 'forex',
    description: 'Norwegian Kroner vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'EURGBP': {
    symbol: 'EURGBP',
    full_name: 'EURGBP',
    type: 'forex',
    description: 'Euro vs Pound Sterling',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'GBP',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
    ],
  },
  'GBPUSD': {
    symbol: 'GBPUSD',
    full_name: 'GBPUSD',
    type: 'forex',
    description: 'Pound Sterling vs US Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'GBPJPY': {
    symbol: 'GBPJPY',
    full_name: 'GBPJPY',
    type: 'forex',
    description: 'Pound Sterling vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'USDHUF': {
    symbol: 'USDHUF',
    full_name: 'USDHUF',
    type: 'forex',
    description: 'US Dollar vs Hungarian Forint',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'HUF',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/HU.svg',
    ],
  },
  'EURSGD': {
    symbol: 'EURSGD',
    full_name: 'EURSGD',
    type: 'forex',
    description: 'Euro vs Singapore Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'SGD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
    ],
  },
  'NZDCHF': {
    symbol: 'NZDCHF',
    full_name: 'NZDCHF',
    type: 'forex',
    description: 'New Zealand Dollar vs Swiss Franc',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CHF',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  'GBPCHF': {
    symbol: 'GBPCHF',
    full_name: 'GBPCHF',
    type: 'forex',
    description: 'Pound Sterling vs Swiss Franc',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CHF',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  'EURNOK': {
    symbol: 'EURNOK',
    full_name: 'EURNOK',
    type: 'forex',
    description: 'Euro vs Norwegian Krone',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'NOK',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/NO.svg',
    ],
  },
  'USDCAD': {
    symbol: 'USDCAD',
    full_name: 'USDCAD',
    type: 'forex',
    description: 'US Dollar vs Canadian Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CAD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  'EURNZD': {
    symbol: 'EURNZD',
    full_name: 'EURNZD',
    type: 'forex',
    description: 'Euro vs New Zealand Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'NZD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
    ],
  },
  'USDSEK': {
    symbol: 'USDSEK',
    full_name: 'USDSEK',
    type: 'forex',
    description: 'US Dollar vs Swedish Krona',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'SEK',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/SE.svg',
    ],
  },
  'NZDCAD': {
    symbol: 'NZDCAD',
    full_name: 'NZDCAD',
    type: 'forex',
    description: 'New Zealand Dollar vs Canadian Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CAD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/NZ.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  'GBPCAD': {
    symbol: 'GBPCAD',
    full_name: 'GBPCAD',
    type: 'forex',
    description: 'Pound Sterling vs Canadian Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CAD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/GB.svg',
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
    ],
  },
  'USDTRY': {
    symbol: 'USDTRY',
    full_name: 'USDTRY',
    type: 'forex',
    description: 'US Dollar vs Turkish Lira',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'TRY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/TR.svg',
    ],
  },
  'USDZAR': {
    symbol: 'USDZAR',
    full_name: 'USDZAR',
    type: 'forex',
    description: 'US Dollar vs South African Rand',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'ZAR',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/ZA.svg',
    ],
  },
  'CADJPY': {
    symbol: 'CADJPY',
    full_name: 'CADJPY',
    type: 'forex',
    description: 'Canadian Dollar vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    currency_code: 'JPY',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
  'CADCHF': {
    symbol: 'CADCHF',
    full_name: 'CADCHF',
    type: 'forex',
    description: 'Canadian Dollar vs Swiss Franc',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'CHF',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/CA.svg',
      'https://s3-symbol-logo.tradingview.com/country/CH.svg',
    ],
  },
  'USDPLN': {
    symbol: 'USDPLN',
    full_name: 'USDPLN',
    type: 'forex',
    description: 'US Dollar vs Polish Zloty ',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    contract_size:100000,
    currency_code: 'PLN',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
      'https://s3-symbol-logo.tradingview.com/country/PL.svg',
    ],
  },
  'EURAUD': {
    symbol: 'EURAUD',
    full_name: 'EURAUD',
    type: 'forex',
    description: 'Euro vs Australian Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 5,
    currency_code: 'AUD',
    contract_size:100000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/EU.svg',
      'https://s3-symbol-logo.tradingview.com/country/AU.svg',
    ],
  },
  'SGDJPY': {
    symbol: 'SGDJPY',
    full_name: 'SGDJPY',
    type: 'forex',
    description: 'Singapore Dollar vs Japanese Yen',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    contract_size:100000,
    currency_code: 'JPY',
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/country/SG.svg',
      'https://s3-symbol-logo.tradingview.com/country/JP.svg',
    ],
  },
};

export const indices_symbols = {
  'AUS200': {
    symbol: 'AUS200',
    full_name: 'AUS200',
    type: 'index',
    description: 'Australia 200 Cash Index',
    session: '0100-0730|0810-2155',
    decimals: 2,
    currency_code: 'AUD',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/asx-200.svg'],
  },
  'EUSTX50': {
    symbol: 'EUSTX50',
    full_name: 'EUSTX50',
    type: 'index',
    description: 'Euro 50 Cash index',
    session: '0700-2100',
    decimals: 2,
    currency_code: 'EURO',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/euro-stoxx-50.svg'],
  },
  'FRA40': {
    symbol: 'FRA40',
    full_name: 'FRA40',
    type: 'index',
    description: 'France 40 Cash Index',
    session: '0700-2100',
    decimals: 2,
    currency_code: 'EURO',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/cac-40.svg'],
  },
  'GER30': {
    symbol: 'GER30',
    full_name: 'GER30',
    type: 'index',
    description: 'Germany 40 Cash Index',
    session: '0700-2100',
    decimals: 1,
    currency_code: 'DAX',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/dow-jones-germany-titans-30.svg'],
  },
  'HK50': {
    symbol: 'HK50',
    full_name: 'HK50',
    type: 'index',
    description: 'Honk Kong',
    session: '0000-2400',
    decimals: 2,
    currency_code: 'HKD',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/country/HK.svg'],
  },
  'JP225': {
    symbol: 'JP225',
    full_name: 'JP225',
    type: 'index',
    description: 'Japan 225 index',
    session: '0000-2400',
    decimals: 0,
    currency_code: 'JPY',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/country/JP.svg'],
  },
  'NDX100': {
    symbol: 'NDX100',
    full_name: 'NDX100',
    type: 'index',
    description: 'NAS100',
    session: '0000-2400',
    decimals: 2,
    currency_code: 'USD',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/dax.svg'],
  },
  'NTH25': {
    symbol: 'NTH25',
    full_name: 'NTH25',
    type: 'index',
    description: 'Netherlands 25 Cash Index',
    session: '00715-2100',
    decimals: 2,
    currency_code: 'EUR',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/country/NL.svg'],
  },
  'SPX500': {
    symbol: 'SPX500',
    full_name: 'SPX500',
    type: 'index',
    description: 'SPX500',
    session: '0715-2100',
    decimals: 2,
    currency_code: 'USD',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/s-and-p-500.svg'],
  },
  'SWI20': {
    symbol: 'SWI20',
    full_name: 'SWI20',
    type: 'index',
    description: 'Switzerland 20 Cash index',
    session: '0700-2100',
    decimals: 2,
    currency_code: 'CHF',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/swiss-20-index.svg'],
  },
  'UK100': {
    symbol: 'UK100',
    full_name: 'UK100',
    type: 'index',
    description: 'UK 100 Cash Index',
    session: '0100-2200',
    decimals: 2,
    currency_code: 'GBP',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/uk-100.svg'],
  },
  'US2000': {
    symbol: 'US2000',
    full_name: 'US2000',
    type: 'index',
    description: 'Russell 2000',
    session: '0100-2200',
    decimals: 1,
    currency_code: 'USD',
    contract_size:10,
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/russell-2000.svg'],
  },
  'US30': {
    symbol: 'US30',
    full_name: 'US30',
    type: 'index',
    description: 'Dow Jones Industrial Average',
    session: '0100-2200',
    contract_size:10,
    decimals: 2,
    currency_code: 'USD',
    exchange: '',
    logo_urls: ['https://s3-symbol-logo.tradingview.com/indices/dow-30.svg'],
  },
};

export const commodity_symbols = {
  'XPTUSD': {
    symbol: 'XPTUSD',
    full_name: 'XPTUSD',
    type: 'commodity',
    description: 'Platinum vs US- DOllar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 2,
    contract_size:100,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/platinum.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'XAUUSD': {
    symbol: 'XAUUSD',
    full_name: 'XAUUSD',
    type: 'commodity',
    description: 'Gold vs US Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 2,
    contract_size:100,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/gold.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'XAGUSD': {
    symbol: 'XAGUSD',
    full_name: 'XAGUSD',
    type: 'commodity',
    description: 'Silver vs US Dollar',
    session: '2100-2400:1|0000-2100:6|0000-2400',
    decimals: 3,
    contract_size:500,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/metal/silver.svg',
      'https://s3-symbol-logo.tradingview.com/country/US.svg',
    ],
  },
  'USOUSD': {
    symbol: 'USOUSD',
    full_name: 'USOUSD',
    type: 'commodity',
    description: 'West Texas Intermidiate Cride Oil Cash',
    session: '0200-2200',
    contract_size:100,
    decimals: 3,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg'
    ],
  },
  'UKOUSD': {
    symbol: 'UKOUSD',
    full_name: 'UKOUSD',
    type: 'commodity',
    description: 'Crude Oil Brent Cash',
    session: '0200-2200',
    decimals: 3,
    contract_size:100,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crude-oil.svg'
    ],
  },
};

export const crypto_symbols={
  'ADAUSD': {
    symbol: 'ADAUSD',
    full_name: 'ADAUSD',
    type: 'crypto',
    description: 'Cardano vs US Dollar',
    session: '0000-2400',
    decimals: 4,
    contract_size:100,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCADA.svg'
    ],
  },
  'BCHUSD': {
    symbol: 'BCHUSD',
    full_name: 'BCHUSD',
    type: 'crypto',
    description: 'Bitcoin Cash vs US Dollar',
    session: '0000-2400',
    decimals: 3,
    contract_size:1,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCBCH.svg'
    ],
  },
  'BTCUSD': {
    symbol: 'BTCUSD',
    full_name: 'BTCUSD',
    type: 'crypto',
    description: 'Bitcoin vs US Dollar',
    session: '0000-2400',
    decimals: 2,
    contract_size:1,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg'
    ],
  },
  'DOGUSD': {
    symbol: 'DOGUSD',
    full_name: 'DOGUSD',
    type: 'crypto',
    description: 'DogeCoin Vs USD',
    session: '0000-2400',
    decimals: 5,
    contract_size:10000,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCDOG.svg'
    ],
  },
  'ETHUSD': {
    symbol: 'ETHUSD',
    full_name: 'ETHUSD',
    type: 'crypto',
    description: 'Ethereum vs US Dollar',
    session: '0000-2400',
    decimals: 2,
    contract_size:1,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCETH.svg'
    ],
  },
  'LNKUSD': {
    symbol: 'LNKUSD',
    full_name: 'LNKUSD',
    type: 'crypto',
    description: 'Link vs US Dollar',
    session: '0000-2400',
    decimals: 3,
    contract_size:100,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCLINK.svg'
    ],
  },
  'LTCUSD': {
    symbol: 'LTCUSD',
    full_name: 'LTCUSD',
    type: 'crypto',
    description: 'Litecoin vs US Dollar',
    session: '0000-2400',
    decimals: 2,
    contract_size:1,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCLTC.svg'
    ],
  },
  'LTCUSD': {
    symbol: 'LTCUSD',
    full_name: 'LTCUSD',
    type: 'crypto',
    description: 'Litecoin vs US Dollar',
    session: '0000-2400',
    decimals: 2,
    contract_size:1,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCLTC.svg'
    ],
  },
  'XLMUSD': {
    symbol: 'XLMUSD',
    full_name: 'XLMUSD',
    type: 'crypto',
    description: 'Stellar vs US Dollar',
    session: '0000-2400',
    decimals: 5,
    contract_size:100,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXLM.svg'
    ],
  },
  'XMRUSD': {
    symbol: 'XMRUSD',
    full_name: 'XMRUSD',
    type: 'crypto',
    description: 'Monero vs US Dollar',
    session: '0000-2400',
    decimals: 2,
    contract_size:1,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXMR.svg'
    ],
  },
  'XRPUSD': {
    symbol: 'XRPUSD',
    full_name: 'XRPUSD',
    type: 'crypto',
    description: 'Ripple vs US dollar',
    session: '0000-2400',
    decimals: 2,
    contract_size:1,
    exchange: '',
    logo_urls: [
      'https://s3-symbol-logo.tradingview.com/crypto/XTVCXRP.svg'
    ],
  },
}
import { io } from "socket.io-client";
import { endsWith, find, isEmpty, remove, toLower, values } from "lodash";
import {
  makeApiRequest,
  getDependencyCountries,
  getFlagFromCountry,
  getSymbolType,
  getSymbol,
  logMessage,
  getErrorMessage,
  logErrorMessage,
  allSymbols,
  isValidSymbol,
} from "./helper.js";
import {
  FN_API_URL,
  FN_WEB_SOCKET_URL,
  GN_API_URL,
  GN_WEB_SOCKET_URL,
} from "./const.js";
import moment from "moment/moment.js";
import { dispatchEvent } from "./eventDispatcher.js";

const _barSubscriptions = {};
const _quoteSubscriptions = {};
const _barSymbolSubscribers = {};
const _quoteSymbolSubscribers = {};
let onCloseCallback = null;

let server = localStorage.getItem("tvServer");
let websocketUrl;
let baseURL = FN_API_URL;
if (server == "gn") {
  websocketUrl = GN_WEB_SOCKET_URL;
} else {
  websocketUrl = FN_WEB_SOCKET_URL;
}

let webSocket;
webSocket = new WebSocket(websocketUrl);

webSocket.onclose = () => {
  var iframe = document.getElementsByTagName("iframe")[0];
  var saveBtn = iframe.contentDocument.getElementById(
    "header-toolbar-save-load"
  );
  if (saveBtn) {
    saveBtn.click();
  }
  logErrorMessage("WebSocket connection close");
  webSocket = new WebSocket(websocketUrl);
  if (onCloseCallback) {
    onCloseCallback();
  }
};

// DatafeedConfiguration implementation
const configurationData = {
  supports_search: false,
  supports_group_request: true,
  supports_marks: false,
  supports_timescale_marks: true,
  supports_time: true,
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: [
    // "1T",
    // "1S",
    // "5S",
    // "10S",
    // "15S",
    // "30S",
    "1",
    "3",
    "5",
    "15",
    "30",
    // "45",
    "60",
    "120",
    "180",
    "240",
    "480",
    "1D",
    "1W",
    "1M",
    // "3M",
    // "6M",
    // "12M",
  ],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: "", name: "C", desc: "$" },
    { value: "OTC", name: "OTC", desc: "Over the counter" },
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [
    { name: "All", value: "" },
    { name: "Forex", value: "forex" },
    { name: "Indices", value: "index" },
    { name: "Commodity", value: "commodity" },
    // { name: "Crypto", value: "crypto" },
  ],
};

// websocket
webSocket.onmessage = (event) => {
  var iframe = document.getElementsByTagName("iframe")[0];
  var saveBtn = iframe.contentDocument.getElementById(
    "header-toolbar-save-load"
  );
  if (saveBtn) {
    saveBtn.click();
  }

  const parseData = JSON.parse(event.data);
  const eventType = parseData.event;
  if (eventType == "quote") {
    handleRealTimeQuoteData(parseData.data);
  } else if (eventType == "candle") {
    handleRealTimeCandleData(parseData.data);
  } else {
    dispatchEvent(eventType, parseData.data);
  }
};

// Socket handle function to process real time candle data
function handleRealTimeCandleData(e) {
  const { symbol, open, high,interval, low, close, time, volume } = e;
  let newsymbol = `${symbol}_${interval}`;
  if (_barSymbolSubscribers[`${newsymbol}`]) {
    const { lastBarTime, listeners } = _barSymbolSubscribers[newsymbol];
    // no need to use past time candles
    if (
      (lastBarTime != null && lastBarTime > time) ||
      open == "NaN" ||
      high == "NaN" ||
      low == "NaN" ||
      close == "NaN" ||
      volume == "NaN"
    ) {
      return;
    }
    let bar = {
      time,
      open,
      high,
      low,
      close,
      volume,
    };
    _barSymbolSubscribers[newsymbol].lastBarTime = time;
    // Send data to every subscriber of that symbol
    listeners.forEach((listener) => {
      listener.listener(bar);
    });
  }
}

// Socket handle function to process real time quote data
function handleRealTimeQuoteData(e) {
  let symbol = e.n;
  if (_quoteSymbolSubscribers[symbol]) {
    const { symbolInfo, listeners } = _quoteSymbolSubscribers[symbol];
    const quote = {
      n: symbolInfo.symbol,
      s: "ok",
      v: {
        ...e.v,
        ch: e.v.ch,
        chp: e.v.chp,
        lp: e.v.lp,
        exchange: "",
        description: symbolInfo.description,
        minmov: 1,
        minmove2: 0,
        original_name: symbolInfo.full_name,
        pricescale: calculatePriceScale(symbolInfo.decimals),
        short_name: symbolInfo.name,
      },
    };
    // Send data to every subscriber of that symbol
    listeners.forEach((listener) => {
      listener.listener([quote]);
    });
  }
}

function calculatePriceScale(decimals) {
  if (decimals == undefined || decimals == null || decimals < 0) {
    decimals = 2;
  }
  return Math.pow(10, decimals);
}

function getSymbolStringForHistorical(symbol, symbolType, resolution) {
  return `${symbol}_${getIntervalFormate(resolution)}`
}

function getIntervalFormate(resolution) {
  const resolutionMap = {
    "1T": "tick",
    "1S": "1S",
    "5S": "S5",
    "10S": "S10",
    "15S": "S15",
    "30S": "S30",
    1: "M1",
    3: "M3",
    5: "M5",
    15: "M15",
    30: "M30",
    45: "M45",
    60: "H1",
    120: "H2",
    180: "H3",
    240: "H4",
    480: "H8",
    "1D": "D1",
    "1W": "W1",
    "1M": "MN",
    "3M": "3MN",
    "6M": "6MN",
    "12M": "12MN",
  };

  return resolutionMap[resolution];
}

function getFromTime(resolution) {
  let duration = parseInt(resolution);
  let unit = "minutes";
  const utcMoment = moment(new Date()).utc().set("millisecond", 0);
  // seconds
  if (endsWith(resolution, "S")) {
    unit = "seconds";
  } // days
  else if (endsWith(resolution, "D")) {
    utcMoment.seconds(0).minutes(0).hours(0);
    unit = "days";
  } // weeks
  else if (endsWith(resolution, "W")) {
    utcMoment.seconds(0).minutes(0).hours(0);
    unit = "weeks";
  } // months
  else if (endsWith(resolution, "M")) {
    utcMoment.seconds(0).minutes(0).hours(0).date(0);
    unit = "month";
  } // ticks
  else if (endsWith(resolution, "T")) {
    duration = 6;
    unit = "seconds";
  } else {
    utcMoment.seconds(0);
    unit = "minutes";
  }
  utcMoment.subtract(duration, unit);
  return utcMoment.valueOf();
}

export const dataFeeder = {
  onReady: (callback) => {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  },
  registerOnCloseCallback: (callback) => {
    onCloseCallback = callback;
  },

  unregisterOnCloseCallback: (callback) => {
    if (onCloseCallback === callback) {
      onCloseCallback = null;
    }
  },
  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    logMessage(
      `searchSymbol: Searching symbols #${userInput} - {${symbolType}}`
    );
    const newSymbols = values(allSymbols).filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      const isSymbolDescriptionContainsInput =
        symbol.description.toLowerCase().indexOf(userInput.toLowerCase()) !==
        -1;
      let typeRes = true;
      if (symbolType) {
        typeRes = symbolType === symbol.type;
      }
      return (
        typeRes &&
        isExchangeValid &&
        (isFullSymbolContainsInput || isSymbolDescriptionContainsInput)
      );
    });
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    setTimeout(() => {
      logMessage(`resolveSymbol: Resolving symbol #${symbolName}`);
      const symbol = getSymbol(symbolName);
      if (!symbol) {
        logMessage(`resolveSymbol: Can not resolving symbol #${symbolName}`);
        onResolveErrorCallback("Cannot resolve symbol");
        return;
      }
      // Symbol information object
      const symbolInfo = {
        ticker: symbol.full_name,
        name: symbol.symbol,
        description: symbol.description,
        type: symbol.type,
        typespecs: symbol.type == "forex" ? [] : ["cfd"],
        session: symbol.session,
        timezone: "Etc/UTC",
        exchange: symbol.exchange,
        minmov: 1,
        pricescale: calculatePriceScale(symbol.decimals),
        visible_plots_set: "ohlcv",
        has_ticks: true, // Supports resolution in ticks
        has_seconds: true, // Supports resolution in seconds
        has_intraday: true, // Supports resolution in minutes
        has_daily: true, // Supports resolution in days
        has_weekly_and_monthly: true, // Supports resolution in weeks/months
        volume_precision: 2,
        data_status: "streaming",
        logo_urls: symbol.logo_urls,
      };
      logMessage(`resolveSymbol: Resolved symbol #${symbolName}`, symbolInfo);
      onSymbolResolvedCallback(symbolInfo);
    }, 0);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    let { from, to, firstDataRequest } = periodParams;
    let { name: symbol, type: symbolType } = symbolInfo;
    const symbolPattern = getSymbolStringForHistorical(
      symbol,
      symbolType,
      resolution
    );
    const intervalPattern = getIntervalFormate(resolution);

    if (server == "gn") {
      baseURL = GN_API_URL;
    } else {
      baseURL = FN_API_URL;
    }

    try {
      const candles = await makeApiRequest(
        "candle_history",
        {
          symbol: symbol,
          from: from * 1000,
          to: to * 1000,
          interval: intervalPattern,
        },
        baseURL
      );
      logMessage("getBars: Historical candles #", candles);
      let bars = [];
      candles.forEach((bar) => {
        if (bar.time >= from * 1000 && bar.time <= to * 1000) {
          let { open, high, low, close, time, volume } = bar;
          bars = [
            ...bars,
            {
              time,
              low,
              high,
              open,
              close,
              volume,
            },
          ];
        }
      });
      logMessage(`getBars: Returned bars count ${bars.length}`);
      if (firstDataRequest) {
        const lastBar = bars[bars.length - 1];
        logMessage("getBars: Last bar #", lastBar);
        _barSymbolSubscribers[symbolPattern] = {
          lastBarTime: lastBar?.time,
          resolution,
          symbolInfo,
          listeners: [],
        };
      }
      onHistoryCallback(bars, { noData: false });
    } catch (e) {
      logErrorMessage(`getBars: ${getErrorMessage(e)}`, e);
      onErrorCallback({ error: getErrorMessage(e) });
    }
  },

  subscribeBars: (symbolInfo, resolution, newDataCallback, listenerGuid) => {
    const symbolPattern = getSymbolStringForHistorical(
      symbolInfo.name,
      symbolInfo.type,
      resolution
    );
    if (
      _barSymbolSubscribers[symbolPattern] &&
      find(_barSymbolSubscribers[symbolPattern].listeners, {
        listenerId: listenerGuid,
      })
    ) {
      logMessage(
        `subscribeBar: Already has subscriber with id=${listenerGuid}`
      );
      return;
    }
    if (isEmpty(_barSymbolSubscribers[symbolPattern].listeners)) {
      const fromTime = getFromTime(resolution);
      const intervalPattern = getIntervalFormate(resolution);

      webSocket.send(
        JSON.stringify({
          type: "candle",
          op: "subscribe",
          interval: intervalPattern,
          from: fromTime,
          to: symbolInfo.name,
        })
      );
    }
    _barSymbolSubscribers[symbolPattern].listeners.push({
      listenerId: listenerGuid,
      listener: newDataCallback,
    });
    _barSubscriptions[listenerGuid] = symbolPattern;
    logMessage(
      `subscribeBar: Subscribed for #${listenerGuid} - {${symbolPattern}, ${resolution}}`
    );
  },

  unsubscribeBars: (listenerGuid) => {
    const symbolPattern = _barSubscriptions[listenerGuid];
    const indexOfColon = symbolPattern.indexOf(":");
    const symbol =
      indexOfColon !== -1
        ? symbolPattern.substring(0, indexOfColon)
        : symbolPattern;

    const lastIndexOfUnderscore = listenerGuid.lastIndexOf("_");

    const resolution =
      lastIndexOfUnderscore !== -1
        ? listenerGuid.substring(lastIndexOfUnderscore + 1)
        : listenerGuid;
    const intervalFound = getIntervalFormate(resolution);

    remove(_barSymbolSubscribers[symbolPattern].listeners, {
      listenerId: listenerGuid,
    });
    if (isEmpty(_barSymbolSubscribers[symbolPattern].listeners)) {
      /*webSocket.send(
        JSON.stringify({
          type: "candle",
          op: "unsubscribe",
          to: symbol,
          interval: intervalFound,
        })
      );*/
      delete _barSymbolSubscribers[symbolPattern];
    }
    delete _barSubscriptions[listenerGuid];
    logMessage(
      `unsubscribeBar: Unsubscribed for #${listenerGuid} - {${symbolPattern}}`
    );
  },

  getQuotes: async (symbols, onDataCallback, onErrorCallback) => {
    logMessage("getQuotes: Quotes for #", symbols);
    try {
      const quotes = await makeApiRequest(
        "last_quote",
        {
          symbol: `${symbols.join(",")}`,
        },
        baseURL
      );

      let historicalQuotes = [];
      for (const quote of quotes) {
        const { eventSymbol: symbol } = quote.v;
        const item = {
          n: symbol,
          s: "ok",
          v: {
            ...quote.v,
            exchange: "",
            description: symbol,
            minmov: 1,
            minmove2: 0,
            original_name: symbol,
            pricescale: 43,
            short_name: symbol,
          },
        };
        historicalQuotes.push(item);
      }
      onDataCallback(historicalQuotes);
    } catch (e) {
      logErrorMessage(`getQuotes: ${getErrorMessage(e)}`, e);
      onErrorCallback({ error: getErrorMessage(e) });
    }
  },
  subscribeQuotes: (symbols, fastSymbols, onRealtimeCallback, listenerGuid) => {
    const subscribeSymbols = [];
    symbols.forEach((symbol) => {
      if (
        _quoteSymbolSubscribers[symbol] &&
        find(_quoteSymbolSubscribers[symbol].listeners, {
          listenerId: listenerGuid,
        })
      ) {
        logMessage(
          `subscribeQuotes: ${symbol} already has subscriber with id=${listenerGuid}`
        );
        return true;
      }
      if (!_quoteSymbolSubscribers[symbol]) {
        subscribeSymbols.push(symbol);
        _quoteSymbolSubscribers[symbol] = {
          symbolInfo: getSymbol(symbol),
          listeners: [],
        };
      }
      _quoteSymbolSubscribers[symbol].listeners.push({
        listenerId: listenerGuid,
        listener: onRealtimeCallback,
      });
    });
    if (!isEmpty(subscribeSymbols)) {
      webSocket.send(
        JSON.stringify({
          type: "quote",
          op: "subscribe",
          to: `${symbols.join(",")}`,
        })
      );
    }
    _quoteSubscriptions[listenerGuid] = symbols;
    logMessage(
      `subscribeQuotes: Subscribed for #${listenerGuid} - `,
      subscribeSymbols
    );
  },
  unsubscribeQuotes: (listenerGuid) => {
    const symbols = _quoteSubscriptions[listenerGuid];
    const unsubscribeSymbols = [];
    symbols.forEach((symbol) => {
      remove(_quoteSymbolSubscribers[symbol].listeners, {
        listenerId: listenerGuid,
      });
      if (isEmpty(_quoteSymbolSubscribers[symbol].listeners)) {
        unsubscribeSymbols.push(symbol);
        delete _quoteSymbolSubscribers[symbol];
      }
    });

    /*
    if (!isEmpty(unsubscribeSymbols)) {
      webSocket.send(
        JSON.stringify({
          type: "quote",
          op: "unsubscribe",
          to: `${symbols.join(",")}`,
        })
      );
    }*/

    delete _quoteSubscriptions[listenerGuid];
    logMessage(
      `unsubscribeQuotes: Unsubscribed for #${listenerGuid} - `,
      unsubscribeSymbols
    );
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    logMessage("getMarks: Getting Marks");
    onDataCallback([
      {
        id: 1,
        time: endDate,
        color: "red",
        text: ["This is the mark pop-up text."],
        label: "M",
        labelFontColor: "blue",
        minSize: 25,
      },
      {
        id: 2,
        time: endDate + 5260000, // 2 months
        color: "red",
        text: ["Second marker"],
        label: "S",
        labelFontColor: "green",
        minSize: 25,
      },
    ]);
  },
  subscribeEvents: (login, token) => {
    webSocket.send(
      JSON.stringify({
        type: "mt5events",
        login: parseInt(login),
        token: token,
      })
    );
  },
};
